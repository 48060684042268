/* Updated styles for .modal-container */
.modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide overflow to prevent horizontal scrolling */
}

/* Close button (top-right) */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

/* Previous and Next buttons */
.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
}

.prev-button {
  left: 10px; /* Adjust the left margin */
}

.next-button {
  right: 10px; /* Adjust the right margin */
}

/* Updated max-height for .modal-image */




.modal-image {
  max-width: 100%;
  max-height: 80vh; /* Adjust the maximum height of the image to be within 80% of the viewport height */
  object-fit: contain;
}
