/* Responsive iFrame */
.responsive-iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.responsive-iframe-container iframe,
.responsive-iframe-container object,
.responsive-iframe-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Hide big-container on small screens */
@media (max-width: 550px) {
  .big-container {
    display: none;
  }
}

/* Hide small-container on large screens */
@media (min-width: 551px) {
  .small-container {
    display: none;
  }
}

@media (max-width: 550px) {
  .responsive-iframe-container.small-container {
    padding-bottom: 150%; /* Increase this value to make the height taller */
  }
}
