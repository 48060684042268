.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 767px) {
  .App-header {
    font-size: calc(12px + 2vmin); /* Slightly larger font for mobile */
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mt-from-navbar {
  margin-top: 20px; /* Gap between navbar and content */
}

body {
  font-family: "Nunito", sans-serif;
  font-size: larger;
  overflow-y: scroll;
}

h1{
  margin-top: 20px;
  margin-bottom: 10px;
}

html, body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.footer {
  margin-top: auto;
}
